import Button from '@/components/shared/Button';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { produce } from 'immer';
import { AllFiltersItems, AllFiltersType } from '@/types';
import { filtersToShowLength } from './FiltersPopup';
import classNames from 'classnames';
import { isStringArray } from '@/utils/arrays/array-string-type-check';
import { useAllFiltersShowState } from '@/atoms/catalog/all-filters';
import { useFiltersState } from '@/atoms/catalog/filters-state';

interface Props {
    filters: AllFiltersItems;
    type: AllFiltersType | AllFiltersType[];
    onOpenPopup: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

const FiltersItemContentList = ({ filters, type, onOpenPopup }: Props) => {
    const [filtersState, setFiltersState] = useFiltersState();
    const [showAllFilters] = useAllFiltersShowState();

    return (
        <div className="filter-row__list">
            {isStringArray(filters)
                ? filters.slice(0, filtersToShowLength).map((filter, i) => {
                      return (
                          <FilterCheckbox
                              key={i}
                              name={filter}
                              value={filter}
                              checked={
                                  typeof type === 'string'
                                      ? !!filtersState[type] &&
                                        // @ts-ignore скоро исправлю
                                        filtersState[type].length > 0 &&
                                        // @ts-ignore скоро исправлю
                                        !!filtersState[type].find((item) => item === filter)
                                      : type.every((item) =>
                                            Array.isArray(filtersState[item])
                                                ? !!filtersState[item]?.find((el) => el === filter)
                                                : !!filtersState[item],
                                        )
                              }
                              onChange={(arg) => {
                                  setFiltersState(
                                      produce((draft) => {
                                          if (arg) {
                                              if (typeof type === 'string') {
                                                  // @ts-ignore скоро исправлю
                                                  draft[type]?.push(filter);
                                              } else {
                                                  type.forEach((item) => {
                                                      if (Array.isArray(draft[item])) {
                                                          // @ts-ignore скоро исправлю
                                                          draft[item]?.push(filter);
                                                      } else {
                                                          // @ts-ignore скоро исправлю
                                                          draft[item] = filter;
                                                      }
                                                  });
                                              }
                                          } else {
                                              if (typeof type === 'string') {
                                                  // @ts-ignore скоро исправлю
                                                  draft[type] = draft[type]?.filter((item) => item !== filter);
                                              } else {
                                                  type.forEach((item) => {
                                                      if (Array.isArray(draft[item])) {
                                                          // @ts-ignore скоро исправлю
                                                          draft[item] = draft[item]?.filter((item) => item !== filter);
                                                      } else {
                                                          // @ts-ignore скоро исправлю
                                                          draft[item] = null;
                                                      }
                                                  });
                                              }
                                          }
                                      }),
                                  );
                              }}
                          >
                              {filter}
                          </FilterCheckbox>
                      );
                  })
                : filters.slice(0, filtersToShowLength).map((filter, i) => {
                      return (
                          <FilterCheckbox
                              key={i}
                              name={filter.name}
                              value={filter.id.toString()}
                              bordered={filter.highlighted}
                              checked={
                                  typeof type === 'string'
                                      ? !!filtersState[type] &&
                                        // @ts-ignore скоро исправлю
                                        filtersState[type].length > 0 &&
                                        // @ts-ignore скоро исправлю
                                        !!filtersState[type].find((item) => item === filter.id)
                                      : type.every((item) =>
                                            Array.isArray(filtersState[item])
                                                ? !!filtersState[item]?.find((el) => el === filter.id)
                                                : !!filtersState[item],
                                        )
                              }
                              onChange={(arg) => {
                                  setFiltersState(
                                      produce((draft) => {
                                          if (arg) {
                                              if (typeof type === 'string') {
                                                  // @ts-ignore скоро исправлю
                                                  draft[type]?.push(filter.id);
                                              } else {
                                                  type.forEach((item) => {
                                                      // @ts-ignore скоро исправлю
                                                      draft[item]?.push(filter.id);
                                                  });
                                              }
                                          } else {
                                              if (typeof type === 'string') {
                                                  // @ts-ignore скоро исправлю
                                                  draft[type] = draft[type]?.filter((item) => item !== filter.id);
                                              } else {
                                                  type.forEach((item) => {
                                                      // @ts-ignore скоро исправлю
                                                      draft[item] = draft[item]?.filter((item) => item !== filter.id);
                                                  });
                                              }
                                          }
                                      }),
                                  );
                              }}
                          >
                              {filter.name}
                          </FilterCheckbox>
                      );
                  })}
            {filters.length > filtersToShowLength && (
                <Button
                    geometryVariant="mustache"
                    className={classNames('all-brands-button m-text-xs js-more-btn', {
                        'is-open': showAllFilters,
                    })}
                    onClick={onOpenPopup}
                    data-type={type}
                >
                    Показать ещё
                </Button>
            )}
        </div>
    );
};

export default FiltersItemContentList;
