import Dropdown from '@/components/shared/Dropdown';
import ArrowMoreSVG from '@/svg/arrow-more.svg';
import { AllFiltersType, ImageShape, PresetFilterItem } from '@/types';
import Image from 'next/image';
import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Filters, useFiltersState } from '@/atoms/catalog/filters-state';
import PresetFiltersList from './PresetFiltersList';
import FiltersItemContentList from './FiltersItemContentList';
import Collapse from '@/components/shared/Collapse';

type Props = {
    label: string;
    onScroll: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    onOpenPopup: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    presetFilters: PresetFilterItem[];
    forms: {
        name: string;
        fieldName: keyof Filters | (keyof Filters)[];
        fieldLabel: string;
        is_standard: boolean;
        sizeList: (string | PresetFilterItem)[];
        image?: ImageShape;
    }[];
    defaultValue: {
        min: number | null;
        max: number | null;
    };
    stateMinValue?: number;
    stateMaxValue?: number;
};

const FiltersSizesItem = ({
    label,
    onScroll,
    onOpenPopup,
    forms,
    presetFilters,
    defaultValue,
    stateMinValue,
    stateMaxValue,
    ...props
}: Props) => {
    const dependentItemRef = useRef<HTMLDivElement>(null);
    const [selected, setSelected] = useState<string | null>(null);
    const [, setFiltersState] = useFiltersState();

    const selectedForm = forms.find((form) => form.name === selected);
    const filters = useMemo(() => selectedForm?.sizeList.map((item) => String(item)) || [], [selectedForm?.sizeList]);

    useEffect(() => {
        if (dependentItemRef.current) {
            dependentItemRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selected]);

    useEffect(() => {
        setFiltersState((prevFiltersState) => {
            const newFiltersState = { ...prevFiltersState };

            forms.forEach((form) => {
                if (Array.isArray(form.fieldName)) {
                    form.fieldName.forEach((fieldName) => {
                        if (fieldName.endsWith('[]')) {
                            (newFiltersState as any)[fieldName] = [];
                        } else {
                            (newFiltersState as any)[fieldName] = null;
                        }
                    });
                } else {
                    if (typeof form.fieldName === 'string') {
                        if (form.fieldName.endsWith('[]')) {
                            (newFiltersState as any)[form.fieldName] = [];
                        } else {
                            (newFiltersState as any)[form.fieldName] = null;
                        }
                    }
                }
            });

            return newFiltersState;
        });
    }, [forms, selected, setFiltersState]);

    return (
        <>
            <Collapse className="select" {...props}>
                <div className="input-group js-input-group filters-sizes-item">
                    <label className="filter-row__name js-filter-row-name m-text-xs">{label}</label>
                    <Collapse.Toggler className="form-control select-toggler" onClick={onScroll}>
                        <ArrowMoreSVG />
                    </Collapse.Toggler>
                </div>
                <Collapse.Content>
                    <div className="filter-row">
                        <div className="filters-sizes-item-buttons-list">
                            {forms.map((form, i) => (
                                <button
                                    key={i}
                                    onClick={() => {
                                        setSelected(selected === form.name ? null : form.name);
                                    }}
                                    aria-label={form.name}
                                    className={classNames('filters-sizes-item-button', {
                                        'is-active': selected === form.name,
                                    })}
                                >
                                    {form.image?.src && (
                                        <Image
                                            src={form.image.src}
                                            width={form.image.width}
                                            height={form.image.height}
                                            alt={form.name || ''}
                                            className="img-fluid filters-sizes-item-button__img"
                                            unoptimized={form.image.src.endsWith('.svg')}
                                        />
                                    )}
                                </button>
                            ))}
                        </div>
                    </div>
                </Collapse.Content>
            </Collapse>

            {filters.length > 0 && selectedForm && (
                <Collapse className="select" {...props} defaultCollapsed={false}>
                    <div className="input-group js-input-group">
                        <label className="filter-row__name js-filter-row-name m-text-xs">
                            {selectedForm.fieldLabel}
                        </label>
                        <Collapse.Toggler className="form-control select-toggler" onClick={onScroll}>
                            <ArrowMoreSVG />
                        </Collapse.Toggler>
                    </div>
                    <Collapse.Content key={selected}>
                        <div ref={dependentItemRef} className="filter-row">
                            {selectedForm.is_standard ? (
                                <PresetFiltersList
                                    defaultValue={defaultValue}
                                    presetFilters={presetFilters}
                                    type="diameter"
                                />
                            ) : (
                                <FiltersItemContentList
                                    filters={filters}
                                    type={selectedForm.fieldName as AllFiltersType}
                                    onOpenPopup={onOpenPopup}
                                />
                            )}
                        </div>
                    </Collapse.Content>
                </Collapse>
            )}
        </>
    );
};

export default FiltersSizesItem;
