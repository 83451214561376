import Button from '@/components/shared/Button';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { useFiltersState } from '@/atoms/catalog/filters-state';
import { produce } from 'immer';
import { AllFiltersItems, AllFiltersType } from '@/types';
import ArrowMoreSVG from '@/svg/arrow-more.svg';
import { FILTER_COLLAPSE_DURATION, filtersToShowLength } from './FiltersPopup';
import { useAllFiltersShowState } from '@/atoms/catalog/all-filters';
import classNames from 'classnames';
import { HTMLAttributes, useState } from 'react';
import Collapse from '@/components/shared/Collapse';

let timer: NodeJS.Timeout | null;

interface Props extends HTMLAttributes<HTMLElement> {
    filters: AllFiltersItems;
    type: AllFiltersType;
    label: string;
    onScroll: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    onOpenPopup: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    defaultOpened?: boolean;
}

const FiltersItem = ({ filters, type, label, onScroll, onOpenPopup, ...props }: Props) => {
    const [filtersState, setFiltersState] = useFiltersState();
    const [showAllFilters] = useAllFiltersShowState();
    const [isOpened, setIsOpened] = useState(false);

    const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        isOpened
            ? timer
                ? (clearTimeout(timer), (timer = null))
                : (timer = setTimeout(() => {
                      setIsOpened(false);
                      timer = null;
                  }, FILTER_COLLAPSE_DURATION))
            : setIsOpened(true);

        onScroll(event);
    };

    const handleFilterChange = (arg: boolean, filter: string | number) => {
        setFiltersState(
            produce((draft) => {
                if (arg) {
                    (draft[type] as (string | number)[]).push(filter);
                } else {
                    (draft[type] as (string | number)[]) = (draft[type] as (string | number)[]).filter(
                        (item) => item !== filter,
                    );
                }
            }),
        );
    };

    const renderCheckboxes = () => {
        return filters.slice(0, filtersToShowLength).map((filter, i) => {
            const isStringFilter = typeof filter === 'string';
            const filterName = isStringFilter ? filter : filter.name;
            const filterValue = isStringFilter ? filter : filter.id.toString();

            const isChecked =
                !!filtersState[type] &&
                (filtersState[type] as (string | number)[]).includes(isStringFilter ? filter : filter.id);

            return (
                <FilterCheckbox
                    key={i}
                    name={filterName}
                    value={filterValue}
                    checked={isChecked}
                    onChange={(arg) => handleFilterChange(arg, isStringFilter ? filter : filter.id)}
                    bordered={!isStringFilter && filter.highlighted}
                >
                    {filterName}
                </FilterCheckbox>
            );
        });
    };

    return (
        <Collapse collapsed {...props}>
            <div className="input-group">
                <label className="filter-row__name js-filter-row-name m-text-xs">{label}</label>
                <Collapse.Toggler className="select-toggler" onToggle={onClickHandler}>
                    <ArrowMoreSVG />
                </Collapse.Toggler>
            </div>
            <Collapse.Content>
                {isOpened && (
                    <div className="filter-row">
                        <div className="filter-row__list">
                            {renderCheckboxes()}
                            {filters.length > filtersToShowLength && (
                                <Button
                                    geometryVariant="mustache"
                                    className={classNames('all-brands-button m-text-xs js-more-btn', {
                                        'is-open': showAllFilters,
                                    })}
                                    onClick={onOpenPopup}
                                    data-type={type}
                                >
                                    Показать ещё
                                </Button>
                            )}
                        </div>
                    </div>
                )}
            </Collapse.Content>
        </Collapse>
    );
};

export default FiltersItem;
